import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SpartaxAuftragskunde, SpartaxAuftragskundeArt, SpartaxAuftragskundeUtil } from 'src/app/data/dto/spartax/spartax-auftragskunde.dto';
import { SpartaxFormModelDto } from 'src/app/data/dto/spartax/spartax-form-model.dto';
import { SpartaxKunde, SpartaxKundeUtil } from 'src/app/data/dto/spartax/spartax-kunde.dto';
import { SpartaxService } from 'src/app/data/service/spartax.service';
import * as moment from 'moment';
import { SpartaxDocumentType, SpartaxDocumentTypeName } from 'src/app/data/dto/spartax/spartax-document.dto';
import { AlertController, IonInput, ModalController } from '@ionic/angular';
import { FormEventsService } from 'src/app/data/service/form-events.service';
import { SpartaxLoadingComponent } from 'src/app/components/spartax-loading/spartax-loading.component';
import { from } from 'linq-to-typescript';
import * as IBAN from 'iban';

@Component({
  selector: 'app-spartax-place-order',
  templateUrl: './spartax-place-order.component.html',
  styleUrls: ['./spartax-place-order.component.scss'],
})
export class SpartaxPlaceOrderComponent implements OnInit {
  @Input()
  model: SpartaxFormModelDto;

  @Output()
  modelChange = new EventEmitter<SpartaxFormModelDto>();

  @Input()
  edit: boolean;

  @Input()
  debug: boolean;

  @ViewChild('formElement')
  ngForm!: NgForm;

  @Input()
  spartaxPortalKunden: Map<string, SpartaxKunde>;

  @Output()
  spartaxPortalKundenChange = new EventEmitter<Map<string, SpartaxKunde>>();

  public readonly possibleAusweisarten = ['Reisepass', 'Personalausweis', 'Führerschein', 'Behindertenpass'];

  public working = false;
  public workingStatus: string;
  public error = false;
  public errorMsg: string;
  public queryingKundenMap = new Map<string, boolean>();
  public loadingKundenMap = new Map<string, boolean>();
  public possibleKundenMatchesMap = new Map<string, {key: string, value: string}[]>();
  public kundeBackupMap = new Map<string, SpartaxKunde>();
  public existingKundeLinkedMap = new Map<string, boolean>();
  public geburtstagMap = new Map<string, number>();
  public geburtsmonatMap = new Map<string, number>();
  public geburtsjahrMap = new Map<string, number>();
  public sterbetagMap = new Map<string, number>();
  public sterbemonatMap = new Map<string, number>();
  public sterbejahrMap = new Map<string, number>();
  public ausstellungstagMap = new Map<string, number>();
  public ausstellungsmonatMap = new Map<string, number>();
  public ausstellungsjahrMap = new Map<string, number>();
  public svNummerWarningMap = new Map<string, boolean>();
  public ibanWarningMap = new Map<string, boolean>();

  private popover: HTMLIonModalElement;
  private kontaktDesAuftragsChanging = false;

  get lowWidth() {
    if (window.innerWidth < 992) {
      return true;
    } else if (window.innerWidth < 1200) {
      return this.columnSizeLg === 12;
    } else {
      return this.columnSizeXl === 12;
    }
  }

  get columnSizeXl() {
    if (this.model.auftragskunden.length <= 2) {
      return 4;
    } else if (this.model.auftragskunden.length <= 3) {
      return 3;
    } else if (this.model.auftragskunden.length <= 5) {
      return 2;
    } else {
      return 12;  
    }
  }

  get columnSizeLg() {
    if (this.model.auftragskunden.length <= 2) {
      return 4;
    } else if (this.model.auftragskunden.length <= 3) {
      return 3;
    } else {
      return 12;  
    }
  }

  constructor(
    private spartaxService: SpartaxService,
    private alertController: AlertController,
    private formEventService: FormEventsService,
    private modalController: ModalController
  ) { }

  ngOnInit() { }

  initSpecialFields (kunde: SpartaxKunde) {
    if (kunde.Geburtstag) {
      this.geburtstagMap.set(kunde.FormModelId, moment(kunde.Geburtstag).date());
      this.geburtsmonatMap.set(kunde.FormModelId, moment(kunde.Geburtstag).month() + 1);
      this.geburtsjahrMap.set(kunde.FormModelId, moment(kunde.Geburtstag).year());
    }
    
    if (kunde.VerstorbenAm) {
      this.sterbetagMap.set(kunde.FormModelId, moment(kunde.VerstorbenAm).date());
      this.sterbemonatMap.set(kunde.FormModelId, moment(kunde.VerstorbenAm).month() + 1);
      this.sterbejahrMap.set(kunde.FormModelId, moment(kunde.VerstorbenAm).year());
    }

    if (kunde.AusstellungsdatumAusweis) {
      this.ausstellungstagMap.set(kunde.FormModelId, moment(kunde.AusstellungsdatumAusweis).date());
      this.ausstellungsmonatMap.set(kunde.FormModelId, moment(kunde.AusstellungsdatumAusweis).month() + 1);
      this.ausstellungsjahrMap.set(kunde.FormModelId, moment(kunde.AusstellungsdatumAusweis).year());
    }
  }
  
  anyPersonHasIncome() {
    for (let auftragskunde of this.model.auftragskunden) {
      if (this.personHasIncome(auftragskunde)) {
        return true;
      }
    }

    return false;
  }

  personHasIncome(auftragskunde: SpartaxAuftragskunde) {
    for (let jahr of auftragskunde.Erklaerungsjahre) {
      if (jahr.EinkunftsArten.length > 0) {
        return true;
      }
    }

    return false;
  }

  anyPersonDeceased() {
    return from(this.model.auftragskunden).any(x => x.Kunde.Verstorben);
  }

  anyPersonNotLinked() {
    return from(this.model.auftragskunden).any(x => !x.Kunde.ListItemId);
  }

  formIsValid() {
    this.errorMsg = "";
    if (this.edit) {
      if (!this.ngForm?.valid) {
        this.errorMsg = "Ein oder mehrere Pflichtfelder sind nicht befüllt.";
        return false;
      }
      for (const auftragsKunde of this.model.auftragskunden) {
        if (!this.isDateValid(this.geburtstagMap.get(auftragsKunde.Kunde.FormModelId), 
          this.geburtsmonatMap.get(auftragsKunde.Kunde.FormModelId), this.geburtsjahrMap.get(auftragsKunde.Kunde.FormModelId))) {
            this.errorMsg = "Ein eingegebenes Geburtsdatum ist ungültig.";
            return false;
        }
        if (auftragsKunde.Kunde.Verstorben && !this.isDateValid(this.sterbetagMap.get(auftragsKunde.Kunde.FormModelId), 
          this.sterbemonatMap.get(auftragsKunde.Kunde.FormModelId), this.sterbejahrMap.get(auftragsKunde.Kunde.FormModelId))) {
            this.errorMsg = "Ein eingegebenes Sterbedatum ist ungültig.";
            return false;
        }
        if (auftragsKunde.TeilDesAuftrags && !this.isDateValid(this.ausstellungstagMap.get(auftragsKunde.Kunde.FormModelId),
          this.ausstellungsmonatMap.get(auftragsKunde.Kunde.FormModelId), this.ausstellungsjahrMap.get(auftragsKunde.Kunde.FormModelId))) {
            this.errorMsg = "Ein eingegebenes Ausstellungsdatum eines Ausweises ist ungültig.";
            return false;
        }
        if (auftragsKunde.Kunde.PLZ.length != 4) {
          this.errorMsg = "Die Postleitzahl muss 4-stellig sein."
          return false;
        }
      }
    }
    return true;
  }

  isHauptkunde(auftragskunde) {
    if (auftragskunde.AuftragsKundeArt == SpartaxAuftragskundeArt.hauptkunde) {
      return true;
    }
    return false;
  }

  markAllControlsAsTouched() {
    for (const control in this.ngForm.controls) {
      this.ngForm.controls[control].markAsTouched();
    }
  }

  canQueryKunden(auftragskunde: SpartaxAuftragskunde) {
    return !this.queryingKundenMap.get(auftragskunde.Kunde.FormModelId) && 
      auftragskunde.Kunde.Vorname && auftragskunde.Kunde.Vorname.length >= 3 &&
      auftragskunde.Kunde.Nachname && auftragskunde.Kunde.Nachname.length >= 3 &&
      (auftragskunde.Kunde.PLZ && auftragskunde.Kunde.PLZ.toString().length >= 4);
  }

  getQueryKundenText(auftragskunde: SpartaxAuftragskunde) {
    return this.canQueryKunden(auftragskunde) ? "In Bestandskunden suchen..." : 
      "Erfassen Sie weitere Daten, um nach Bestandskunden zu suchen...";
  }

  async queryKunden(auftragskunde: SpartaxAuftragskunde) {
    const kunde = auftragskunde.Kunde;
    if (!kunde.ListItemId && this.canQueryKunden(auftragskunde)) {
      try {
        this.queryingKundenMap.set(kunde.FormModelId, true);
        let query = "";
  
        query += (kunde.TitelVorgestellt ? kunde.TitelVorgestellt + " " : "")  
          + (kunde.Vorname ? kunde.Vorname + " " : "")
          + (kunde.Nachname ? kunde.Nachname : "")
          + (kunde.TitelNachgestellt ? " " + kunde.TitelNachgestellt : "") 
          + (kunde.PLZ ? " " + kunde.PLZ : "");

        this.possibleKundenMatchesMap.set(kunde.FormModelId, await this.spartaxService.queryCustomers(query));
      } catch(error) {
        console.error(error);
      }
      this.queryingKundenMap.set(kunde.FormModelId, false);
    }
  }

  async onExistingCustomerSelected(key: string, auftragsKunde: SpartaxAuftragskunde) {
    /*
      NOTE -> Currently, this method is duplicated in the spartax-identity and spartax-place-order components,
      because a change to the functionality is already planned and will be implemented in the future.
    */
    if(from(this.model.auftragskunden).any(x => x.Kunde.ListItemId === +key)) {
      const alert = await this.alertController.create({
        subHeader: 'Der ausgewählte Kunde ist bereits einem anderen Auftragskunden zugeordnet.',
        buttons: ['OK']
      });
      await alert.present();
    } else {
      const oldFormModelId = auftragsKunde.Kunde.FormModelId;
      const kunde = auftragsKunde.Kunde;
      const kundeBackup = {...kunde};
      this.loadingKundenMap.set(oldFormModelId, true);
      const kundeFromSharePoint = await this.spartaxService.getCustomerById(+key);
      SpartaxKundeUtil.updateKundePropsFromAnother(kundeFromSharePoint, kunde);
      this.kundeBackupMap.set(kunde.FormModelId, kundeBackup);
      kunde.procedureDiscussed = true;
      kunde.Partner = kundeBackup.Partner;
      kunde.FormModelIdPartner = kundeBackup.FormModelIdPartner;
      kunde.ListItemIdPartner = kundeBackup.ListItemIdPartner;
      kunde.Familienstaende = kundeBackup.Familienstaende;
      kunde.GemeinsamerHaushalt = kundeBackup.GemeinsamerHaushalt;
        
      //Swap all references to the old formModelId in auftragskunden
      for(const auftragskunde of this.model.auftragskunden) {
        if(auftragskunde.KundeFormModelId === kundeBackup.FormModelId) {
          auftragskunde.KundeFormModelId = kundeFromSharePoint.FormModelId;
        }
  
        if(auftragskunde.Kunde.FormModelIdPartner === kundeBackup.FormModelId) {
          auftragskunde.Kunde.FormModelIdPartner = kundeFromSharePoint.FormModelId;
        }
  
        if(auftragskunde.Kunde.ListItemIdPartner === kundeBackup.ListItemId) {
          auftragskunde.Kunde.ListItemIdPartner = kundeFromSharePoint.ListItemId;
        }
  
        if(auftragskunde.Kunde.FormModelIdMutter === kundeBackup.FormModelId) {
          auftragskunde.Kunde.FormModelIdMutter = kundeFromSharePoint.FormModelId;
        }
        
        if(auftragskunde.Kunde.FormModelIdVater === kundeBackup.FormModelId) {
          auftragskunde.Kunde.FormModelIdVater = kundeFromSharePoint.FormModelId;
        }
  
        if(auftragskunde.Kunde.FormModelIdsKinder) {
          for(let i = 0; i < auftragskunde.Kunde.FormModelIdsKinder.length; i++) {
            if(auftragskunde.Kunde.FormModelIdsKinder[i] === kundeBackup.FormModelId) {
              auftragskunde.Kunde.FormModelIdsKinder[i] = kundeFromSharePoint.FormModelId;
            }
          }
        }
  
        if(auftragskunde.Kunde.ListItemIdsKinder) {
          for(let i = 0; i < auftragskunde.Kunde.ListItemIdsKinder.length; i++) {
            if(auftragskunde.Kunde.ListItemIdsKinder[i] === kundeBackup.ListItemId) {
              auftragskunde.Kunde.ListItemIdsKinder[i] = kundeFromSharePoint.ListItemId;
            }
          }
        }
      }

      for(const kind of this.model.kinder) {
        if(kind.KundeFormModelId === kundeBackup.FormModelId) {
          kind.KundeFormModelId = kundeFromSharePoint.FormModelId;
        }
      }
  
      this.spartaxPortalKunden.set(kunde.FormModelId, kunde);
      this.spartaxPortalKundenChange.emit(this.spartaxPortalKunden);
      this.modelChange.emit(this.model);

      this.loadingKundenMap.set(oldFormModelId, false);
      this.existingKundeLinkedMap.set(kunde.FormModelId, true);
      this.initSpecialFields(kunde);
    }
  }

  onRemoveExistingCustomerLink(auftragskunde: SpartaxAuftragskunde) {
    const oldFormModelId = auftragskunde.Kunde.FormModelId;
    auftragskunde.Kunde = {...this.kundeBackupMap.get(auftragskunde.Kunde.FormModelId)};
    from(this.model.auftragskunden).first(x => x.KundeFormModelId === oldFormModelId).KundeFormModelId = auftragskunde.Kunde.FormModelId;
    this.modelChange.emit(this.model);
    this.spartaxPortalKunden.delete(auftragskunde.Kunde.FormModelId);
    this.spartaxPortalKundenChange.emit(this.spartaxPortalKunden);
    this.existingKundeLinkedMap.set(auftragskunde.Kunde.FormModelId, false);
  }

  isDateValid (tag: number, monat: number, jahr: number) {
    if (tag || monat || jahr) {
      if (!(tag && monat && jahr)) {
        this.errorMsg = "Ein eingegebenes Datum ist unvollständig."
        return false;
      }
      if (jahr < 1900 || jahr > (new Date()).getFullYear()) {
        this.errorMsg = "Ein eingegebener Jahr-Wert ist ungültig."
        return false;
      }
      if (monat < 1 || monat > 12) {
        this.errorMsg = "Ein eingegebener Monats-Wert ist ungültig."
        return false;
      }
      if (tag < 1 || tag > 31) {
        this.errorMsg = "Ein eingegebener Tag-Wert ist ungültig."
        return false;
      }
    }
    this.errorMsg = "";
    return true;
  }

  isAuftragskundeOlderThan18(auftragskunde: SpartaxAuftragskunde) {
    if (auftragskunde.Kunde.Geburtstag) {
      return moment(auftragskunde.Kunde.Geburtstag).toDate() < moment().subtract(18, 'years').toDate();
    } else {
      return false;
    }
  }

  getGeburtstag(auftragskunde: SpartaxAuftragskunde) {
    return this.geburtstagMap.get(auftragskunde.Kunde.FormModelId);
  }

  getGeburtsmonat(auftragskunde: SpartaxAuftragskunde) {
    return this.geburtsmonatMap.get(auftragskunde.Kunde.FormModelId);
  }

  getGeburtsjahr(auftragskunde: SpartaxAuftragskunde) {
    return this.geburtsjahrMap.get(auftragskunde.Kunde.FormModelId);
  }

  setGeburtstag(event: Event, auftragskunde: SpartaxAuftragskunde) {
    this.geburtstagMap.set(auftragskunde.Kunde.FormModelId, +(event.target as unknown as IonInput).value);
    this.saveBirthDate(auftragskunde);
  }

  setGeburtsmonat(event: Event, auftragskunde: SpartaxAuftragskunde) {
    this.geburtsmonatMap.set(auftragskunde.Kunde.FormModelId, +(event.target as unknown as IonInput).value);
    this.saveBirthDate(auftragskunde);
  }

  setGeburtsjahr(event: Event, auftragskunde: SpartaxAuftragskunde) {
    this.geburtsjahrMap.set(auftragskunde.Kunde.FormModelId, +(event.target as unknown as IonInput).value);
    this.saveBirthDate(auftragskunde);
  }
  
  setKundeVerstorben(event: Event, auftragskunde: SpartaxAuftragskunde) {
    auftragskunde.Kunde.Verstorben = (event.target as unknown as HTMLInputElement).checked;
    if (!auftragskunde.Kunde.Verstorben) {
      auftragskunde.Kunde.VerstorbenAm = null;
      this.sterbetagMap.delete(auftragskunde.Kunde.FormModelId);
      this.sterbemonatMap.delete(auftragskunde.Kunde.FormModelId);
      this.sterbejahrMap.delete(auftragskunde.Kunde.FormModelId);
    }
  }

  saveBirthDate(auftragskunde: SpartaxAuftragskunde) {
    const geburtstag = this.geburtstagMap.get(auftragskunde.Kunde.FormModelId);
    const geburtsmonat = this.geburtsmonatMap.get(auftragskunde.Kunde.FormModelId);
    const geburtsjahr = this.geburtsjahrMap.get(auftragskunde.Kunde.FormModelId);
    if (this.isDateValid(geburtstag, geburtsmonat, geburtsjahr)) {
      auftragskunde.Kunde.Geburtstag = moment(`${geburtsjahr}-${geburtsmonat}-${geburtstag}`).toDate();
    } else {
      auftragskunde.Kunde.Geburtstag = null;
    }
  }
  
  validateSVNummer(svNummer: number) {
    const svNummerStr = svNummer.toString();
    if(svNummerStr && svNummerStr.length == 10) {
      const pruefZifferCalc = (
        (+svNummerStr.charAt(0) * 3) +
        (+svNummerStr.charAt(1) * 7) +
        (+svNummerStr.charAt(2) * 9) +
        (+svNummerStr.charAt(4) * 5) +
        (+svNummerStr.charAt(5) * 8) +
        (+svNummerStr.charAt(6) * 4) +
        (+svNummerStr.charAt(7) * 2) +
        (+svNummerStr.charAt(8) * 1) +
        (+svNummerStr.charAt(9) * 6)
      ) % 11;

      if(pruefZifferCalc.toString() == svNummerStr.charAt(3)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  validateIban(iban: string) {
    return IBAN.isValid(iban);
  }

  onSvNummerChange(event: Event, auftragskunde: SpartaxAuftragskunde) {
    let svNummer = +(event.target as unknown as IonInput).value;
    if (svNummer > 999 && svNummer < 10000 && auftragskunde.Kunde.Geburtstag) {
      svNummer = Number.parseInt(`${svNummer}${moment(auftragskunde.Kunde.Geburtstag).format("DDMMYY")}`);
    }
    auftragskunde.Kunde.SVNummer = svNummer;
    //Clear warning if SVNummer is valid
    if(this.validateSVNummer(svNummer)) {
      this.svNummerWarningMap.set(auftragskunde.Kunde.FormModelId, false);
    }
  }

  onFocusOutSvNummer(event: Event, auftragskunde: SpartaxAuftragskunde) {
    this.svNummerWarningMap.set(auftragskunde.Kunde.FormModelId, !this.validateSVNummer(auftragskunde.Kunde.SVNummer));
  }

  getIban(auftragskunde: SpartaxAuftragskunde) {
    if (auftragskunde.Kunde.IBAN) {
      //add spaces to IBAN after every 4 characters
      return auftragskunde.Kunde.IBAN.match(/.{1,4}/g).join(' ');
    } else {
      return '';
    }
  }

  onIbanChange(event: Event, auftragskunde: SpartaxAuftragskunde) {
    auftragskunde.Kunde.IBAN = ((event.target as unknown as IonInput).value + '').replaceAll(' ', '');
    //Clear warning if IBAN is valid
    if(this.validateIban(auftragskunde.Kunde.IBAN)) {
      this.ibanWarningMap.set(auftragskunde.Kunde.FormModelId, false);
    }
  }

  onFocusOutIban(event: Event, auftragskunde: SpartaxAuftragskunde) {
    this.ibanWarningMap.set(auftragskunde.Kunde.FormModelId, !this.validateIban(auftragskunde.Kunde.IBAN));
  }

  getSterbetag(auftragskunde: SpartaxAuftragskunde) {
    return this.sterbetagMap.get(auftragskunde.Kunde.FormModelId);
  }

  getSterbemonat(auftragskunde: SpartaxAuftragskunde) {
    return this.sterbemonatMap.get(auftragskunde.Kunde.FormModelId);
  }

  getSterbejahr(auftragskunde: SpartaxAuftragskunde) {
    return this.sterbejahrMap.get(auftragskunde.Kunde.FormModelId);
  }

  setSterbetag(event: Event, auftragskunde: SpartaxAuftragskunde) {
    this.sterbetagMap.set(auftragskunde.Kunde.FormModelId, +(event.target as unknown as IonInput).value);
    this.saveDeathDate(auftragskunde);
  }

  setSterbemonat(event: Event, auftragskunde: SpartaxAuftragskunde) {
    this.sterbemonatMap.set(auftragskunde.Kunde.FormModelId, +(event.target as unknown as IonInput).value);
    this.saveDeathDate(auftragskunde);
  }

  setSterbejahr(event: Event, auftragskunde: SpartaxAuftragskunde) {
    this.sterbejahrMap.set(auftragskunde.Kunde.FormModelId, +(event.target as unknown as IonInput).value);
    this.saveDeathDate(auftragskunde);
  }

  saveDeathDate(auftragskunde: SpartaxAuftragskunde) {
    const sterbetag = this.sterbetagMap.get(auftragskunde.Kunde.FormModelId);
    const sterbemonat = this.sterbemonatMap.get(auftragskunde.Kunde.FormModelId);
    const sterbejahr = this.sterbejahrMap.get(auftragskunde.Kunde.FormModelId);
    if (this.isDateValid(sterbetag, sterbemonat, sterbejahr)) {
      auftragskunde.Kunde.VerstorbenAm = moment(`${sterbejahr}-${sterbemonat}-${sterbetag}`).toDate();
    } else {
      auftragskunde.Kunde.VerstorbenAm = null;
    }
  }
  
  getPhoneNumber(auftragskunde: SpartaxAuftragskunde) {
    return auftragskunde.Kunde.Telefon1?.replace('+43', '');
  }

  setPhoneNumber(event: Event, auftragskunde: SpartaxAuftragskunde) {
    let value = (event.target as unknown as IonInput).value?.toString();
    if(value && value.length > 0) {
      value = value.replaceAll('[^\\d]', '').replaceAll('_','').replaceAll(' ', '').replace('+43', '');
      if(value.startsWith('0')) {
        value = value.substring(1);
      }
      if(value.length > 0) {
        value = '+43' + value;
      }
    } else {
      if(auftragskunde.Telefon1IstKontaktDesAuftrags) {
        auftragskunde.Telefon1IstKontaktDesAuftrags = false;
      }
    }

    auftragskunde.Kunde.Telefon1 = value;
  }

  getAusstellungstag(auftragskunde: SpartaxAuftragskunde) {
    return this.ausstellungstagMap.get(auftragskunde.Kunde.FormModelId);
  }

  getAusstellungsmonat(auftragskunde: SpartaxAuftragskunde) {
    return this.ausstellungsmonatMap.get(auftragskunde.Kunde.FormModelId);
  }

  getAusstellungsjahr(auftragskunde: SpartaxAuftragskunde) {
    return this.ausstellungsjahrMap.get(auftragskunde.Kunde.FormModelId);
  }

  setAusstellungstag(event: Event, auftragskunde: SpartaxAuftragskunde) {
    this.ausstellungstagMap.set(auftragskunde.Kunde.FormModelId, +(event.target as unknown as IonInput).value);
    this.saveAusstellungsdatum(auftragskunde);
  }

  setAusstellungsmonat(event: Event, auftragskunde: SpartaxAuftragskunde) {
    this.ausstellungsmonatMap.set(auftragskunde.Kunde.FormModelId, +(event.target as unknown as IonInput).value);
    this.saveAusstellungsdatum(auftragskunde);
  }

  setAusstellungsjahr(event: Event, auftragskunde: SpartaxAuftragskunde) {
    this.ausstellungsjahrMap.set(auftragskunde.Kunde.FormModelId, +(event.target as unknown as IonInput).value);
    this.saveAusstellungsdatum(auftragskunde);
  }

  saveAusstellungsdatum(auftragskunde: SpartaxAuftragskunde) {
    const ausstellungstag = this.ausstellungstagMap.get(auftragskunde.Kunde.FormModelId);
    const ausstellungsmonat = this.ausstellungsmonatMap.get(auftragskunde.Kunde.FormModelId);
    const ausstellungsjahr = this.ausstellungsjahrMap.get(auftragskunde.Kunde.FormModelId);
    if (this.isDateValid(ausstellungstag, ausstellungsmonat, ausstellungsjahr)) {
      auftragskunde.Kunde.AusstellungsdatumAusweis = moment(`${ausstellungsjahr}-${ausstellungsmonat}-${ausstellungstag}`).toDate();
    } else {
      auftragskunde.Kunde.AusstellungsdatumAusweis = null;
    }
  }

  onEmailChange(event: Event, auftragskunde: SpartaxAuftragskunde) {
    const value = (event.target as unknown as IonInput).value;
    if(value) {
      if(this.isHauptkunde(auftragskunde) && !this.getKontaktDesAuftrags()) {
        auftragskunde.EmailIstKontaktDesAuftrags = true;
      }
    } else {
      if (auftragskunde.EmailIstKontaktDesAuftrags) {
        auftragskunde.EmailIstKontaktDesAuftrags = false;
      }
    }
  }

  getKontaktDesAuftrags() {
    return SpartaxAuftragskundeUtil.getKontaktDesAuftrags(this.model.auftragskunden);
  }

  getAuftragskundeKontaktDesAuftrags(auftragskunde: SpartaxAuftragskunde) {
    if (auftragskunde.EmailIstKontaktDesAuftrags && auftragskunde.Kunde.Email) {
      return 'Email';
    } else if (auftragskunde.Telefon1IstKontaktDesAuftrags && this.getPhoneNumber(auftragskunde)) {
      return 'Telefon1';
    } else {
      return null;
    }
  }

  setAuftragskundeKontaktDesAuftrags(event: Event, auftragskunde: SpartaxAuftragskunde) {
    if (!this.kontaktDesAuftragsChanging) {
      this.kontaktDesAuftragsChanging = true;

      for (const ak of this.model.auftragskunden) {
        ak.EmailIstKontaktDesAuftrags = false;
        ak.Telefon1IstKontaktDesAuftrags = false;
        ak.Telefon2IstKontaktDesAuftrags = false;
        ak.Telefon3IstKontaktDesAuftrags = false;
      }

      const value = (event.target as unknown as IonInput).value;
      if (value === 'Email') {
        auftragskunde.EmailIstKontaktDesAuftrags = true;
      } else if (value === 'Telefon1') {
        auftragskunde.Telefon1IstKontaktDesAuftrags = true;
      } else if (value === 'Telefon2') {
        auftragskunde.Telefon2IstKontaktDesAuftrags = true;
      } else if (value === 'Telefon3') {
        auftragskunde.Telefon3IstKontaktDesAuftrags = true;
      }

      //Override change handlers of the other auftragskunden
      setTimeout(() => {
        this.kontaktDesAuftragsChanging = false;  
      }, 100);
    }
  }

  isDocumentAvailable(auftragskunde: SpartaxAuftragskunde, documentType: SpartaxDocumentType) {
    const portalKunde = this.spartaxPortalKunden.get(auftragskunde.Kunde.FormModelId);

    if (portalKunde) {
      return portalKunde.Dokumente?.find((d) => d.DocumentType == documentType) != null;
    } else {
      return false;
    }
  }

  isDocumentExpired(auftragskunde: SpartaxAuftragskunde, documentType: SpartaxDocumentType) {
    const portalKunde = this.spartaxPortalKunden.get(auftragskunde.Kunde.FormModelId);

    if (portalKunde) {
      const document = portalKunde.Dokumente?.find((d) => d.DocumentType == documentType);
      if (document) {
        return moment(document.TimeLastModified).isBefore(document.TemplateValidFrom);
      }
    }

    return false;
  }

  async createDocument(auftragskunde: SpartaxAuftragskunde, documentType: SpartaxDocumentType) {
    this.markAllControlsAsTouched();
    this.error = false;

    if (!this.spartaxPortalKunden.get(auftragskunde.Kunde.FormModelId) && !await this.showCreateKundePrompt()) {
      await this.dismissPopOver();
      return;
    }
    
    try {
      await this.createOrUpdateKunde(auftragskunde);
    } catch (error) {
      console.error(error);
      this.error = true;
      this.workingStatus = 'Fehler beim Speichern des Kunden  (' + moment().format('HH:mm:ss') + ')';
      await this.reloadPopOver();
      return;
    }

    this.formEventService.triggerSubmitEvent();
    this.working = true;
    this.workingStatus = 'Dokument wird erstellt...';
    await this.reloadPopOver();

    try {
      const fileName = `${SpartaxDocumentTypeName.get(documentType)} ${SpartaxKundeUtil.getVollstaendigerNameOfKunde(auftragskunde.Kunde)}.pdf`;
      const fileBlob = await this.spartaxService.getCustomerDocument(auftragskunde.Kunde.FormModelId, documentType);
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(fileBlob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      this.working = false;
      this.workingStatus = '';
      await this.dismissPopOver();
    } catch (error) {
      console.error(error);
      this.error = true;
      this.workingStatus = 'Fehler beim Erstellen des Dokuments  (' + moment().format('HH:mm:ss') + ')';
      await this.reloadPopOver();
    }
  }

  private async createOrUpdateKunde(auftragskunde: SpartaxAuftragskunde) {
    this.working = true;
    this.workingStatus = 'Kunde wird im Spartax Portal gespeichert...';
    await this.reloadPopOver();

    try {
      const editor = { DisplayName: this.model.editor, UserPrincipalName: this.model.editor };
      if (!auftragskunde.Kunde.ListItemId) {
        auftragskunde.Kunde.Author = editor;
      }
      auftragskunde.Kunde.Editor = editor;
      const createdKunde = await this.spartaxService.createOrUpdateCustomer(auftragskunde.Kunde);
      this.model.auftragskunden.find((ak) => ak.Kunde.FormModelId == auftragskunde.Kunde.FormModelId).Kunde.ListItemId = createdKunde.ListItemId;
      this.spartaxPortalKunden.set(auftragskunde.Kunde.FormModelId, createdKunde);
      this.working = false;
      this.workingStatus = '';
      await this.dismissPopOver();
    } catch (error) {
      console.error(error);
      this.error = true;
      this.workingStatus = 'Fehler beim Speichern des Kunden  (' + moment().format('HH:mm:ss') + ')';
      await this.reloadPopOver();
    }
  }

  async showCreateKundePrompt(): Promise<boolean> {
    let result = false;

    const alert = await this.alertController.create({
      subHeader: 'Der Kunde ist noch nicht im Spartax Portal vorhanden. Soll er jetzt angelegt werden?',
      buttons: [
        {
          text: 'Nein',
          role: 'cancel',
          handler: () => {
            result = false;
          }
        },
        {
          text: 'Ja',
          role: 'confirm',
          handler: () => {
            result = true;
          },
        },
      ],
    });

    await alert.present();
    await alert.onDidDismiss();
    return result;
  }
    
  async uploadDocument(event: Event, auftragskunde: SpartaxAuftragskunde, documentType: SpartaxDocumentType) {
    this.error = false;
    const input = document.createElement('input');
    input.type = 'file';

    input.click();

    input.onchange = async () => {
      const file = input.files[0];

      if (file) {
        this.working = true;
        await this.reloadPopOver();

        this.workingStatus = 'Dokument wird hochgeladen...';
        await this.reloadPopOver();

        try {
          await this.spartaxService.createOrUpdateCustomerDocument(auftragskunde.Kunde.FormModelId, documentType, file.name, file);
          this.working = false;
          this.workingStatus = '';
          this.spartaxPortalKunden.set(auftragskunde.Kunde.FormModelId, await this.spartaxService.getCustomer(auftragskunde.Kunde.FormModelId));
          await this.dismissPopOver();
        } catch (error) {
          console.error(error);
          this.error = true;
          this.workingStatus = 'Fehler beim Hochladen des Dokuments (' + moment().format('HH:mm:ss') + ')';
          await this.reloadPopOver();
        }
      }
    };
  }

  isVollmachtRequired(auftragskunde: SpartaxAuftragskunde) {
    return auftragskunde.TeilDesAuftrags && auftragskunde.Kunde.Geburtstag && moment(auftragskunde.Kunde.Geburtstag).toDate() < moment().subtract(18, 'years').toDate();
  }

  isVollmachtAvailable(auftragskunde: SpartaxAuftragskunde) {
    return this.isDocumentAvailable(auftragskunde, SpartaxDocumentType.Vollmacht);
  }

  isVollmachtExpired(auftragskunde: SpartaxAuftragskunde) {
    return this.isDocumentExpired(auftragskunde, SpartaxDocumentType.Vollmacht);
  }

  async createVollmacht(auftragskunde: SpartaxAuftragskunde) {
    await this.createDocument(auftragskunde, SpartaxDocumentType.Vollmacht);
  }

  async reloadPopOver() {
    try {
      await this.popover.dismiss();
    } catch (error) { }

    this.popover = await this.modalController.create({
      component: SpartaxLoadingComponent,
      componentProps: {
        loadingtext: this.workingStatus
      }
    });

    setTimeout(async () => {
      await this.popover.present();
    }, 100);
  }

  async dismissPopOver() {
    try {
      await this.popover.dismiss();
    } catch (err) { }
  }

  uploadVollmacht(event, auftragskunde: SpartaxAuftragskunde) {
    this.uploadDocument(event, auftragskunde, SpartaxDocumentType.Vollmacht);
  }

  isBankgeheimnisAvailable(auftragskunde: SpartaxAuftragskunde) {
    return this.isDocumentAvailable(auftragskunde, SpartaxDocumentType.Bankgeheimnis);
  }

  isBankgeheimnisExpired(auftragskunde: SpartaxAuftragskunde) {
    return this.isDocumentExpired(auftragskunde, SpartaxDocumentType.Bankgeheimnis);
  }

  async createBankgeheimnis(auftragskunde: SpartaxAuftragskunde) {
    await this.createDocument(auftragskunde, SpartaxDocumentType.Bankgeheimnis);
  }

  uploadBankgeheimnis(event, auftragskunde: SpartaxAuftragskunde) {
    this.uploadDocument(event, auftragskunde, SpartaxDocumentType.Bankgeheimnis);
  }

  isSepaAvailable(auftragskunde: SpartaxAuftragskunde) {
    return this.isDocumentAvailable(auftragskunde, SpartaxDocumentType.SEPA);
  }

  isSepaExpired(auftragskunde: SpartaxAuftragskunde) {
    return this.isDocumentExpired(auftragskunde, SpartaxDocumentType.SEPA);
  }

  async createSepa(auftragskunde: SpartaxAuftragskunde) {
    await this.createDocument(auftragskunde, SpartaxDocumentType.SEPA);
  }

  uploadSepa(event, auftragskunde: SpartaxAuftragskunde) {
    this.uploadDocument(event, auftragskunde, SpartaxDocumentType.SEPA);
  }
}
